import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, Select } from 'antd';
// import { Dropdown } from 'react-bootstrap';
import Footer from '../../components/Footer/Index';
import useReconciledStatementList from './useReconciledStatementList';
import {
  getReconciledStatementTableColumns,
  getReconciledStatementInnerTableColumns
} from './FirmTrustHelper';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import '../../App.css';
import '../../assets/css/style.css';
import moment from 'moment';
import { API, defaultPaginationLimit, get } from '../../config';
import { toast } from 'react-toastify';
import TableComponent from '../ato/TableComponent';

// const dateFormat = 'MM-YYYY';
// const dateFormat = 'DD-MM-YYYY';
const dateFormat = ["DD-MM-YYYY", "DD/MM/YYYY"];
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const ReconciledStatement = ({ setBalanceInfo, setDateFilter, dateFilter }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { RangePicker } = DatePicker;
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  //   const search = query.get("search");
  const currentPage = query.get('page');
  // const [fromDate, setFromDate] = useState('');
  // const [toDate, setToDate] = useState('');
  const [limit, setLimit] = useState(query.get('limit') ?? defaultPaginationLimit);

  // const getDefaultDates = (from, to) => {
  //   if (from && to) {
  //     return { start_date: from, end_date: to };
  //   } else {
  //     const previousYear = new Date();
  //     previousYear.setMonth(previousYear.getMonth() - 6);
  //     // const pmonth = previousYear.getMonth() + 1;
  //     // const pday = previousYear.getDate();
  //     const pyear = previousYear.getFullYear();
  //     const previousYearDate = `01-04-${pyear}`;
  //     const currentTime = new Date();
  //     const month = currentTime.getMonth() + 1;
  //     const day = currentTime.getDate();
  //     const year = currentTime.getFullYear();
  //     const currentDate = `${day}-${month}-${year}`;
  //     return { start_date: previousYearDate, end_date: currentDate };
  //   }
  // };

  // const [dateFilter, setDateFilter] = useState(() => getDefaultDates());

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
    }
  };
  // const [exportLoading, setExportLoading] = useState(false);
  const {
    reconciledStatements,
    isDataLoading,
    fetchReconciledStatement,
    pagingData,
    onPageChange
    // onChange,
    // searchParam,
  } = useReconciledStatementList(
    currentPage,
    dateFilter.start_date,
    dateFilter.end_date,
    setBalanceInfo,
    selectedTab
  );
  const [localStatements, setLocalStatements] = useState([]);

  useEffect(() => {
    setLocalStatements(reconciledStatements);
  }, [reconciledStatements]);

  // const handlePanelChange = (val) => {
  //   const lastDay =
  //     moment(new Date()).format('DD-MM-YYYY') === moment(val[1]).format('DD-MM-YYYY')
  //       ? moment(val[1]).format('DD')
  //       : moment(new Date(moment(val[1]).format('YYYY'), moment(val[1]).format('MM'), 0)).format(
  //           'DD'
  //         );
  //   if (val) {
  //     // setFromDate(moment(val[0]).format('DD-MM-YYYY'));
  //     // setToDate(moment(val[1]).format('DD-MM-YYYY'));
  //     setFromDate(`01-${moment(val[0]).format('MM')}-${moment(val[0]).format('YYYY')}`);
  //     setToDate(
  //       `${lastDay ? lastDay : '01'}-${moment(val[1]).format('MM')}-${moment(val[1]).format(
  //         'YYYY'
  //       )}`
  //     );
  //   } else {
  //     setFromDate('');
  //     setToDate('');
  //   }
  // };
  const handleUndo = async (id) => {
    try {
      const { data } = await get(`${API.UNDO_BANK_RECONCILIATION}/${id}`);
      toast.success(data.message);
      fetchReconciledStatement();
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const innerTableColumns = useMemo(() => {
    return getReconciledStatementInnerTableColumns();
  }, []);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/firm-bank-account?page=1&tab=reconciled-items&limit=${value}`, {
      replace: true
    });
    fetchReconciledStatement(1, value);
  };

  // const handleExport = async (format) => {
  //   setExportLoading(true);
  //   try {
  //     const { data } = await get(
  //       `${API.BANK_RECONCILIATION_LIST}?status=1&from=${fromDate}&to=${toDate}&export=1&format=${format}`
  //     );
  //     const export_report_path = data.data.file;
  //     if (export_report_path) {
  //       var a = document.createElement('a');
  //       a.href = export_report_path;
  //       var file = export_report_path.split('/');
  //       a.setAttribute('download', file[file.length - 1] || 'bank_reconciled_report.pdf');
  //       a.setAttribute('target', '_blank');
  //       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  //       a.click();
  //       a.remove();
  //       setExportLoading(false);
  //     }
  //   } catch (e) {
  //     setExportLoading(false);
  //     const errors = e.response?.data?.errors;
  //     Object.keys(errors).forEach((key) => {
  //       toast.error(errors[key][0]);
  //     });
  //   }
  // };

  return (
    <>
      <div className="cms-page">
        <div className="table-top-btn">
          <div className="search-section" style={{ marginBottom: '15px' }}>
            {/* <RangePicker onChange={handlePanelChange} format={'DD-MM-YYYY'} /> */}
            {/* <RangePicker
              picker="month"
              format={dateFormat}
              disabledDate={disabledDate}
              onChange={handlePanelChange}
            /> */}
            <RangePicker
              defaultValue={[
                dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
                dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
              ]}
              style={{ borderRadius: '30px' }}
              onChange={handleDateChange}
              disabledDate={disabledDate}
              format={dateFormat}
            />
          </div>
          {/* <div className="table-btn">
            <Dropdown>
              <Dropdown.Toggle variant="primary">
                {exportLoading ? 'Loading…' : 'Export'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport('csv')}>CSV</Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport('pdf')}>PDF</Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport('xlsx')}>Excel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
        {/* <p style={{ textAlign: 'right', fontSize: '12px', margin: '-15px 0 20px' }}>
          <a
            href="https://trust-easy-file-storage.s3.ap-southeast-2.amazonaws.com/public/common/docs/BankFeedsintoHL.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#000' }}
          >
            Instructions on HandiLedger Import
          </a>
        </p> */}
        <div style={{ width: '100%', height: '85%' }}>
          <TableComponent
            rowKey={(record) => record.key}
            columns={getReconciledStatementTableColumns(handleUndo)}
            nestedTableColumns={innerTableColumns}
            dataSource={localStatements}
            isNested={true}
            loading={isDataLoading}
            pagingData={pagingData}
            onPageChange={(page) => onPageChange(page, limit)}
            limit={query.get('limit') ?? limit}
            pagination={{
              hideOnSinglePage: true,
              defaultCurrent: 1,
              showQuickJumper: true,
              onChange: (page) => {
                onPageChange(page);
              },
              itemRender: PaginationItemRenderer
            }}
          />
          {localStatements?.length > 0 && <div className="mt-2">
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          </div>}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ReconciledStatement;
